const getEachBook = (key, callback) => {
    if (window.Telegram) {
      const tg = window.Telegram.WebApp;
      tg.ready();
  
      // Получение данных из CloudStorage
      tg.CloudStorage.getItem(key, (error, result) => {
        if (error) {
          console.error('Ошибка при получении данных из CloudStorage:', error);
          callback(error, null);
          return;
        }
        if (result) {
          console.log("Данные найдены для ключа:", key, result);
          const parsedResult = JSON.parse(result);
          callback(null, parsedResult);
        } else {
          console.log('Данные не найдены для ключа:', key);
          callback(null, null); // Возвращаем null, если данные не найдены
        }
      });
    }
  };
  
  export default getEachBook;
  