const saveSettings = (key, backColor, textColor, textSize, textInt) => {
    
    if (window.Telegram) {
      const tg = window.Telegram.WebApp;
      tg.ready();
      
      // Объект с данными для сохранения
      const dataToSave = {
        backColor: backColor,
        textColor: textColor,
        textSize: textSize,
        textInt: textInt
      };
      
      // Функция для сохранения данных в CloudStorage
      const saveData = () => {
        tg.CloudStorage.setItem(key, JSON.stringify(dataToSave), (error, success) => {
          if (!error) {
            console.log('Данные успешно сохранены');
          } else {
            console.error('Ошибка при сохранении данных в CloudStorage', error);
          }
        });
      };
  
      // Перезаписываем данные для указанного ключа
      saveData();
    }
  };
  
  export default saveSettings;
  