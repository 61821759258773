import '../css/Search.css'
// import eye from '../image/eye.png'
// import hudLet from '../image/hudLet.png'
// import detektive from '../image/detective.png'
// import fentezyUfo from '../image/fentezyUfo.png'
// import fentezy from '../image/fentezy.png'
// import roman from '../image/roman.png'
// import triller from '../image/triller.png'
// import biographi from '../image/biographi.png'
// import business from '../image/business.png'
// import history from '../image/history.png'
// import education from '../image/education.png'
// import poezia from '../image/poeziya.png'
// import adventure from '../image/adventure.png'
import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router'
// import { Link } from 'react-router-dom'

const ListCategories = props => {

    const [categories, setCategories] = useState([])

    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        const allAd = props.filterData
        if (allAd) {
        // Извлекаем значения `genre` из массива объектов `allAd`
        const genres = allAd.map(ad => ad.formItems.genre);
        
        // Удаляем дубликаты с помощью `Set` и преобразуем обратно в массив
        const uniqueGenres = [...new Set(genres)];
    
        // Обновляем состояние с уникальными значениями
        setCategories(uniqueGenres);
        console.log(uniqueGenres)
        }
    }, [props]); 

    const goToCategorie = (genre) => {
        navigate(`/genre`, { state: { genre, from: location} })
    }

    return (
        <div>
            {categories &&
            categories.map((cat, ind) =>
                <div className="eachList" key={ind} onClick={()=> goToCategorie(cat)}>
                    <div className="contChart">{cat.charAt(0).toUpperCase()}</div>
                     <p className="textList">{cat}</p>
            </div> 
            )}
        <div style={{borderTop:"1px solid rgb(194, 194, 194)", paddingBottom:"35px", width:"100%"}}/>
            {/* <div className="eachList">
                <img src={hudLet} alt="o" className="imgList" />
                <p className="textList">Художественная литература</p>
            </div>
            <div className="eachList">
                <img src={detektive} alt="o" className="imgList" />
                <p className="textList">Детектив</p>
            </div>
            <div className="eachList">
                <img src={fentezyUfo} alt="o" className="imgList" />
                <p className="textList">Фэнтези</p>
            </div>
            <div className="eachList">
                <img src={fentezy} alt="o" className="imgList" />
                <p className="textList">Научная фантастика</p>
            </div>
            <div className="eachList">
                <img src={roman} alt="o" className="imgList" />
                <p className="textList">Роман</p>
            </div>
            <div className="eachList">
                <img src={triller} alt="o" className="imgList" />
                <p className="textList">Триллер</p>
            </div>
            <div className="eachList">
                <img src={biographi} alt="o" className="imgList" />
                <p className="textList">Биография</p>
            </div>
            <div className="eachList">
                <img src={business} alt="o" className="imgList" />
                <p className="textList">Бизнес</p>
            </div>
            <div className="eachList">
                <img src={history} alt="o" className="imgList" />
                <p className="textList">История</p>
            </div>
            <div className="eachList" >
                <img src={education} alt="o" className="imgList" />
                <p className="textList">Саморазвитие</p>
            </div>
            <div className="eachList" >
                <img src={adventure} alt="o" className="imgList" />
                <p className="textList">Приключения</p>
            </div>
            <div className="eachList" style={{borderBottom:"1px solid rgb(194, 194, 194)", paddingBottom:"5px"}}>
                <img src={poezia} alt="o" className="imgList" />
                <p className="textList">Поэзия</p>
            </div> */}
        </div>
    )
}

export default ListCategories