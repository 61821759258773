import React, { useEffect, useState } from 'react';
import '../css/Progress.css';

const Progress = ({ bookText, numberPage, backColor, textColor }) => {
    const [sections, setSections] = useState([]);

    useEffect(() => {
        // Извлекаем секции из текста FB2
        const sectionMatches = [...bookText.matchAll(/<section>/g)];
        setSections(sectionMatches);
    }, [bookText]);

    const progressPercentage = ((numberPage) / sections.length) * 100;

    return (
        <div className="progress-container">
            <div
                className="progress-bar"
                style={{
                    backgroundColor: backColor,
                    boxShadow: backColor === '#1E1E1E' ? "0px 0px 1px rgba(255, 254, 254, 1)" : "0px 0px 2px rgba(0, 0, 0, 0.4)"
                }}
            >
                <div
                    className="progress-fill"
                    style={{
                        width: `${progressPercentage}%`,
                        backgroundColor: textColor,
                        opacity: "0.7"
                    }}
                />
            </div>
        </div>
    );
};

export default Progress;
