import '../css/AllAd.css'
import '../css/Search.css'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CardsRentTransport from './CardsRentTransport';
import { useEffect, useState } from 'react';
import {getFirestore, doc, getDoc, updateDoc} from 'firebase/firestore'; // Firebase Modular SDK импорты
import { initializeApp } from 'firebase/app';
import firebaseConfig from '../js/firebaseConfig';
// import eye from '../image/eye.png'
import { LoadingScreen } from './LoadingScreen';
import back from '../image/leftBl.png'

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const AllAdGenre = props => {

const navigate = useNavigate()
const location = useLocation()
const genre = location.state.genre; 

const [arrArts, seArrArts] = useState([])
const [genAd, setGenAd] = useState([])

useEffect(()=>{
  seArrArts(JSON.parse(localStorage.getItem('artsArray')) || [])
//Ищем книги с нужным жанром
const filteredGenre = props.allData.filter((ad) => 
ad.formItems.genre.toLowerCase().includes(genre.toLowerCase()) )
setGenAd(filteredGenre)
  // console.log("Данне из всех объявлений", props)
},[props, genre])

//Проверка на просмотр карточек, если просмотрено, надпись просмотрено
const getShown = (number, id) =>{
  if (arrArts.includes(number)) {
  } else {
  postShow(id)
  const updatedItems = [...arrArts, number]
  localStorage.setItem('artsArray', JSON.stringify(updatedItems));
  seArrArts(JSON.parse(localStorage.getItem('artsArray')) || [])
}
}
//Добавляем просмотр в базу данных
const postShow = async (id) => {
    try {
      const documentRef = doc(db, 'selbo', id);
      const documentSnapshot = await getDoc(documentRef);
      if (documentSnapshot.exists()) {
        const currentData = documentSnapshot.data();
        const currentCountShow = currentData.countShow;
        const newCountShow = currentCountShow + 1;

    await updateDoc(documentRef, {countShow: newCountShow});
     } else {
        return false
      }
    }
    catch (error) {
      console.error('Ошибка при обновлении документа:', error);
      return false;
    }
}
const stepBack = () => {
    navigate(-1)
}


if (props.allData) {
    return (
        <div className="contSearch"> 
            <div className="headSearch" style={{marginBottom:"10px", paddingBottom:"10px", borderBottom:"1px solid grey"}}>
                <img src={back} alt="<" className="leftSearch" onClick={stepBack}/>
                <p style={{margin:"auto", fontSize:"18px", fontWeight:"bold"}}>{genre}</p>
            </div>
        <div className='contAllAd'>
            <div className="cards" >
                {genAd && genAd.map((ad, index) => 
                <div key={index} style={{position:"relative"}}>
                  <Link  onClick={()=>{getShown(ad.formItems.art, ad.id)}} className='linkAllAd' to={`/ad${ad.formItems.art}`}>
                  <CardsRentTransport ad={ad.formItems} />
                  </Link>
                </div>)}
                
            </div>
        </div>
        </div>
    )
} else {
  return (
    <LoadingScreen/>
  )
}
}

export {AllAdGenre}