import '../css/CardsAd.css'
import { useState, useEffect } from 'react';

const CardsRentTransport = props => {

const [fb2Url, setFb2Url] = useState(null)

useEffect(() => {
    setFb2Url(props.ad.imageUrl[1])
  }, [props]);

const moment = require('moment');

require('moment/locale/ru');

if (props.ad.dateAd) {
    const dateAd = moment(props.ad.dateAd).format('YYYYMMDD HH:mm:ss');
    var spentTime = moment(dateAd, "YYYYMMDD hh:mm:ss").fromNow()
} else spentTime=null

    if (props.ad) {
    return (

<div className="cardEach">
  {props.ad.imageUrl && (
    <div className="imageCont" style={{ backgroundImage: `url(${fb2Url})`}}>
      <img src={fb2Url} alt="Фото не добавлено" />
    </div>
  )}
  <div className="contentAd">
    <h3 style={{fontWeight:"bold"}}>
      {props.ad.nameBook} 
    </h3>
    <h3 style={{color:"grey"}}>
    {props.ad.author}
    </h3>
  </div>
</div>


    )} else {
        return (<div></div>)
    }
}
export default CardsRentTransport