import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import "../css/Reader.css";
import saveBookLocal from './saveBookLocal';
import pageLeft from '../image/leftEmpty.png'
import pageRight from '../image/rightEmpty.png'
import selbo from '../image/selboEmpty.png'
import plusAdd from '../image/plusAdd.png'
import minusAdd from '../image/minusAdd.png'
import setting from '../image/settingEmpty.png'
import saveSettings from './saveSettings';
import getSettings from './getSettings';
import leftWhite from '../image/leftWhite.png'
import rightWhite from '../image/rightWhite.png'
import settingWhite from '../image/settingWhite.png'
import selboWhite from '../image/selboWhite.png'
import plusWhite from '../image/plusWhite.png'
import minusWhite from '../image/minusWhite.png'
import Progress from './progrees';
import saveEachBook from './saveEachBook';
import { LoadingScreen } from './LoadingScreen';
import { testParser } from './testParser';

const Reader = props =>  {
    const navigate = useNavigate();
    const location = useLocation();

    const linkBook = location.state.linkBook; 
    const art = location.state.art
    const page = location.state.page
    const part = location.state.part
    // const page = 6
    // const part = 500

    const [bookPages, setBookPages] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [isReading, setIsReading] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);
    const [visModal, setVisModal] = useState(false)
    // const [currentScrollSave, setCurrentScrollSave] = useState(0)

    const [fontSize, setFontSize] = useState(16)
    const [inter, setInter] = useState(1.5)
    const [backColor, setBackColor] = useState("#ffe5bd")
    const [textColor, setTextColor] = useState("#000000")
    const [check, setCheck] = useState(false)

    const [showPercent, setShowPercent] = useState(false)

    //Анимация прозрачности
    const [classText, setClassText] = useState("book-textR")
    const [classImage, setClassImage] = useState("cont-image")

    //Записываем высоту страницы
    const [childHeight, setChildHeight] = useState(0)
    //Записываем высоту заполнения скроллинга
    const [propgressScroll, setProgreeScroll] = useState(0)
    //Для передачи в прогресс
    const [bookText, setBookText] = useState(null)

    // const [percent, setPercent] = useState(0)

    //Вычисляем высоту прогресса в процентах
    const countHeight = (countScroll, pageHeight) => {
        const heightScreen = window.innerHeight;
        console.log("ПОСТУПАЕТ ВОТ ТАКАЯ ВЕЛИЧИНА!!!", countScroll)
        console.log("А высота страницы вот такая", pageHeight)
        const a = parseInt((countScroll+heightScreen)/(pageHeight/100))
        console.log("ВЫЧИСЛЯЕМ ВОТ ТАКУЮ ВЕЛИЧИНУ", a)
        if (a>100) {
            setProgreeScroll(100)
        } else {
            setProgreeScroll(a)
        }
    }

    //Пробуем убрать скроллинг вниз в телеграмм
    useEffect(() => {
        console.log("СРАБОТАЛ ЮЗ ЭФФЕКТ 1")
        setTimeout(()=>{
            setCheck(!check)
        },15000)
        window.scrollTo({ top: 0, behavior: 'smooth' });
        let scrollTimeout;
        
        const handleScroll = () => {
            if (scrollTimeout) {
                clearTimeout(scrollTimeout);
            }

            scrollTimeout = setTimeout(() => {
                console.log('Автоматическая прокрутка вверх через 0.5 секунды');
                window.scrollTo({ top: 0, behavior: 'smooth' });
                if (bookTextRef.current) {
                }
            }, 1500); 
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            clearTimeout(scrollTimeout);
        };
    }, []);


    const plusSize = () => {
        if (fontSize < 32) {
        setFontSize(fontSize+1)
        saveSize(fontSize+1)
        }
    }
    const minusSize = ()=> {
        if (fontSize > 5) {
        setFontSize(fontSize-1)
        saveSize(fontSize-1)
        }
    }
    const plusInter = () => {
        if (inter < 2.1) {
        setInter(inter+0.1)
        saveInter(inter+0.1)
        }
    }
    const minusInter = () => {
        if (inter>0.9) {
        setInter(inter-0.1)
        saveInter(inter-0.1)
        }
    }

    const saveColor = (backC, textC) => {
        const key = "settings"
        saveSettings(key, backC, textC, fontSize, inter);
    }
    const saveSize = (size)=> {
        const key = "settings"
        saveSettings(key, backColor, textColor, size, inter);
    }
    const saveInter = (sizeInter)=> {
        const key = "settings"
        saveSettings(key, backColor, textColor, fontSize, sizeInter);
    }


    // Ссылка на контейнер с текстом для прокрутки
    const bookTextRef = useRef(null);

    // Загрузка книги по ссылке с сервера и парсинг
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
          if (bookTextRef.current) {
          countHeight(0, bookTextRef.current.scrollHeight)
          }
        sendBookLinkToServer(linkBook);
        setCurrentPage(parseInt(page))
        const whatScroll = parseInt(part)
        console.log("П  Р   О   М   А   Т   Ы   В   А   Е   М", whatScroll)
        if (isReading) {
        window.scrollTo({
            top: whatScroll,
            behavior: 'smooth'
          });
          if (bookTextRef.current) {
          countHeight(whatScroll, bookTextRef.current.scrollHeight)
          }
        }
          const key = "settings"
          //ВЕРНУТЬ!!!!!!!!!!!!!!!!!
          getSettings(key, (error, data) => {
            if (error) {
              console.error("Ошибка:", error);
            } else if (data) {
                setBackColor(data.backColor)
                setTextColor(data.textColor)
                setFontSize(data.textSize)
                setInter(data.textInt)
              console.log("Полученные данные:", data);
            } else {
              console.log("Данные не найдены");
            }
          }
          );

    }, [linkBook, isReading]);
    //После загрузки книги переходим на нужную страницу и на нужное место на странице

    const currentPageRef = useRef(currentPage);

        useEffect(() => {
            currentPageRef.current = currentPage;
        }, [currentPage]);

    const handleNextPage = () => { 
        console.log("Текущая загруженная старница!!!!!!!!!!", currentPageRef.current)
        if (currentPageRef.current===0) {
            setClassImage("cont-image-animeR")
            setClassText("book-textR-anime0")
            setTimeout(()=>{
                setClassImage("cont-image")
                setClassText("book-textR")
        },1000)
        } else {
            setClassText("book-textR-animeR")
            setTimeout(()=>{
                setClassText("book-textR")
        },1000)
    }

        setTimeout(()=>{setCurrentPage((prevPage) => {
            const newPage = Math.min(prevPage + 1, bookPages.length - 1);
            // console.log(newPage);
            return newPage;
        })},500)
        // setTimeout(()=>{setFirstPage((prevPage) => {
        //     const newPage = Math.min(prevPage + 1, bookPages.length - 1);
        //     return newPage;
        // })},500)
        const pageNow = currentPageRef.current+1
        // console.log("ЭТОООООООООООООЛОДЛВОЖДОВЖДЛОВЖДОВЖД",pageNow)
        const key = "nameBook"
        console.log("Какие данные сохраняем?", key, art, linkBook, pageNow)
        saveBookLocal(key, art, linkBook, pageNow, 0)
        saveEachBook(art, art, linkBook, pageNow, 0)
    };

    const handlePrevPage = () => {
        setClassText("book-textR-animeL")
        setClassImage("cont-image-animeL")
        setTimeout(()=>{
            setClassText("book-textR")
            setClassImage("cont-image")
            
    },1000)
        setTimeout(()=>{
        setCurrentPage((prevPage) => {
            const newPage = Math.max(prevPage - 1, 0);
            console.log(newPage);
            return newPage;
        });
    },500)
        const pageNow = currentPageRef.current-1
        const key = "nameBook"
        saveBookLocal(key, art, linkBook, pageNow, 0)
        saveEachBook(art, art, linkBook, pageNow, 0)
    };

    // Обработчики свайпов влево и вправо
    useEffect(() => {
        let startX = 0;
        let endX = 0;

        const handleTouchStart = (e) => {
            startX = e.touches[0].clientX;
            setShowPercent(true)
        };

        const handleTouchEnd = (e) => {
            endX = e.changedTouches[0].clientX;
            handleSwipe();
            setTimeout(()=>{setShowPercent(false)},800) 
        };

        const handleSwipe = () => {
            const deltaX = startX - endX;
            if (deltaX > 120) {
                console.log("Свайп вправо");
                handleNextPage();
            } else if (deltaX < -120) {
                console.log("Свайп влево");
                handlePrevPage();
            }
        };

        window.addEventListener("touchstart", handleTouchStart);
        window.addEventListener("touchend", handleTouchEnd);

        return () => {
            window.removeEventListener("touchstart", handleTouchStart);
            window.removeEventListener("touchend", handleTouchEnd);
        };
    }, [bookPages.length]);

//Пока не понятно зачем дублируется функционал
    useEffect(() => {
        if (isReading && bookTextRef.current) {
          setCurrentPage(parseInt(page));
          setChildHeight(bookTextRef.current.scrollHeight);
          console.log("Еще одно измерение высоты страницы в зависимости от высоты элемента", bookTextRef.current.scrollHeight)
          bookTextRef.current.scrollTo({
            top: part,
            behavior: 'smooth',
          });
        }
      }, [isReading]);
    
    //Пробуем поменять цвет шапки
    useEffect(() => {
        if (window.Telegram) {
          const tg = window.Telegram.WebApp;
          tg.ready();
          tg.setHeaderColor(backColor)
          tg.setBackgroundColor('#000000')
          tg.disableVerticalSwipes()    
        } 
      }, [backColor]);
  
  useEffect(() => {
      //Отслеживание прокрутки
    const handleScroll = () => {
    console.log("КРУУУУУУУУУУУТИМ")
    const key = "nameBook";
    const currentScroll = bookTextRef.current.scrollTop;
  
    // Очистка предыдущего таймера, если прокрутка продолжается
    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current);
    }
  
    // Устанавливаем таймер для сохранения позиции после завершения скроллинга
    scrollTimeoutRef.current = setTimeout(() => {
    console.log(currentScroll)
    if (bookTextRef.current) {
        
      countHeight(currentScroll, bookTextRef.current.scrollHeight)
    }
    console.log("При прокрутке сохраняем страницу!!!!!", currentPage)
      saveBookLocal(key, art, linkBook, currentPage, currentScroll);
      saveEachBook(art, art, linkBook, currentPage, currentScroll)
    //   setCurrentScrollSave(currentScroll)
    }, 300); // Сохранение произойдет через 300 мс после завершения скроллинга
  };
    
    const bookTextElement = bookTextRef.current;
  
    if (bookTextElement) {
      bookTextElement.addEventListener('scroll', handleScroll);
    }
  
    return () => {
      if (bookTextElement) {
        bookTextElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [currentPage, check]);
  
  // Используйте useRef для хранения таймера между рендерами
  const scrollTimeoutRef = useRef(null);
  
          
    // Отправка ссылки на сервер и получение FB2-файла
    const sendBookLinkToServer = async (bookUrl) => {
        try {
            const response = await fetch('https://tranquil-citadel-64673-5b2d65271b20.herokuapp.com/api/downloadBook', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ url: bookUrl })
            });

            if (!response.ok) {
                throw new Error('Ошибка при получении файла с сервера');
            }

            const bookText = await response.text();
            setBookText(bookText)
            readFB2File(bookText);  // Парсим FB2-файл
        } catch (error) {
            setBookPages(['Ошибка при загрузке файла книги.']);
            console.error('Ошибка при загрузке книги:', error);
        }
    };

    const readFB2File = (fb2Content) => {
        parseFB2(fb2Content);
    };

    const parseFB2 = (fb2Content) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(fb2Content, "application/xml");

        const parserError = xmlDoc.getElementsByTagName("parsererror");
        if (parserError.length > 0) {
            setBookPages(['Ошибка при парсинге FB2 файла.']);
            return;
        }

        const newPages = [];

        // Извлечение изображения, если оно есть
        const binaryElements = xmlDoc.getElementsByTagName("binary");
        if (binaryElements.length > 0) {
            const imageData = binaryElements[0].textContent.trim();
            const imageType = binaryElements[0].getAttribute('content-type');
            setImageSrc(`data:${imageType};base64,${imageData}`);

            // Первая страница будет картинкой книги
            newPages.push('<div class="book-image-page"></div>');
        }

        setBookPages(testParser(fb2Content));
        setIsReading(true);
    };

    // Прокрутка текста наверх при смене страницы
    useEffect(() => {
        if (bookTextRef.current) {
            bookTextRef.current.scrollTo(0, 0);
            //Измеряем высоту элемента
            setChildHeight(bookTextRef.current.scrollHeight);
            console.log("Узнаем вычислилась ли высота страницы??", bookTextRef.current.scrollHeight)
        }
    }, [currentPage]);

    const goHome = ()=> {
        if (window.Telegram) {
            const tg = window.Telegram.WebApp;
            tg.ready();
            tg.setHeaderColor('#cce3fd')
            tg.setBackgroundColor('#ffffff')
          } 
        navigate('/')
    }

    return (
        <div className="AppR" style={{backgroundColor:backColor, overflowY:"scroll"}}>
           {showPercent && 
           <div style={{position:"fixed", left:"5px", top:"5px", backgroundColor: textColor, padding:"3px", textAlign:"center",borderTopRightRadius:"3px", borderBottomRightRadius:"3px", opacity:"0.5"}}>
             <p style={{color:backColor, margin:"0px"}}>{propgressScroll}%</p>
           </div>
           }
            <div className="progress-scroll-cont" style={{borderRight:`0.5px solid ${textColor}`}}>
                <div style={{backgroundColor:textColor, width:"100%", height:`${propgressScroll}%`}}/>
            </div>
            {isReading ? (
                <div className="book-containerR">
                    {imageSrc && currentPage === 0 ? 
                        <div className={classImage}>
                            <img src={imageSrc} alt="Картинка книги" className="book-imageR" />
                        </div>:
                    
                    <div
                        className={classText}
                        style={{fontSize:`${fontSize}px`, lineHeight:`${inter}`, color: textColor}}
                        ref={bookTextRef}
                        dangerouslySetInnerHTML={{ __html: bookPages[currentPage] }}
                    />}
                    {visModal ?
                    <>
                    <div className="pagination-controlsModal" style={{position:"relative"}} >
                    <div style={{position:"absolute",  width:"100%", top:"-27px"}}>
                        <Progress bookText={bookText} numberPage={currentPage} backColor={backColor} textColor={textColor}/>
                        </div>
                        <img src={backColor==='#1E1E1E'? leftWhite: pageLeft} alt="<" onClick={()=>currentPage !== 0 ? handlePrevPage():null} className="pageLR" style={currentPage === 0 ? { opacity:"0.2", marginLeft:"3%" } : {marginLeft:"3%" }}/>
                        <img src={backColor==='#1E1E1E' ? selboWhite: selbo} alt="S" onClick={goHome} className="btnSelbo"/>
                        <img src={backColor==='#1E1E1E'? settingWhite: setting} alt="o" className="btnSelbo" onClick={()=> setVisModal(!visModal)}/>
                        <img src={backColor==='#1E1E1E'?rightWhite: pageRight} alt="<" onClick={()=>currentPage !== bookPages.length - 1 ? handleNextPage():null} className="pageLR" style={currentPage === bookPages.length - 1 ? { opacity:"0.2", marginRight:"3%" } : {marginRight:"3%"}}/>
                    </div>
                    <div className="contSet" style={{borderBottom: backColor==='#1E1E1E'? "1px solid white": "1px solid grey", borderTop: backColor==='#1E1E1E'? "1px solid white": "1px solid grey"}}>
                        <div className="contLeft">
                        <div className="contReg" style={{marginBottom:"10px"}}>
                                <img src={backColor==='#1E1E1E'?minusWhite: minusAdd} alt="-" style={{width:"25px", height:"25px", marginRight:"5px", opacity: fontSize===5 ? 0.2 : ''}} onClick={minusSize}/>
                                <p style={{margin:"0px", color:backColor==='#1E1E1E'? "white":"black"}}>текст</p>
                                <img src={backColor==='#1E1E1E'?plusWhite: plusAdd} alt="+" style={{width:"25px", height:"25px", marginLeft:"5px", opacity: fontSize===32 ? 0.2 : '' }} onClick={plusSize}/>
                        </div>
                        <div className="contReg">
                                <img src={backColor==='#1E1E1E'?minusWhite: minusAdd} alt="-" style={{width:"25px", height:"25px", marginRight:"5px", opacity: inter<0.9 ? 0.2 : ''}} onClick={minusInter}/>
                                <p style={{marginBottom:"19px", margin:"0px", color:backColor==='#1E1E1E'? "white":"black"}}>интер</p>
                                <img src={backColor==='#1E1E1E'?plusWhite: plusAdd} alt="+" style={{width:"25px", height:"25px", marginLeft:"5px", opacity: inter>2.1 ? 0.2 : ''}} onClick={plusInter}/>
                        </div>
                        </div>
                        <div className="allTheme">
                            <div className="theme1" style={{backgroundColor:"#FAF8EF", color:"#333333"}} 
                                onClick={() => {
                                    setBackColor('#FAF8EF');
                                    setTextColor('#333333');
                                    saveColor('#FAF8EF','#333333')
                                  }}>Т</div>
                            <div className="theme1" style={{backgroundColor:"#1E1E1E ", color:"#E0E0E0"}}  
                                onClick={() => {
                                    setBackColor('#1E1E1E');
                                    setTextColor('#E0E0E0');
                                    saveColor('#1E1E1E','#E0E0E0')
                                  }}>Т</div>
                            <div className="theme1" style={{backgroundColor:"#F4ECD8", color:"#4A3B31"}}  
                                onClick={() => {
                                    setBackColor('#F4ECD8');
                                    setTextColor('#4A3B31');
                                    saveColor('#F4ECD8','#4A3B31')
                                  }}>Т</div>
                             <div className="theme1" style={{backgroundColor:"#FFFFF0", color:"#000000"}}  
                                onClick={() => {
                                    setBackColor('#FFFFF0');
                                    setTextColor('#000000');
                                    saveColor('#FFFFF0','#000000')
                                  }}>Т</div>
                            <div className="theme1" style={{backgroundColor:"#ffe5bd", color:"#000000", marginRight:"0px"}}  
                                onClick={() => {
                                    setBackColor('#ffe5bd');
                                    setTextColor('#000000');
                                    saveColor('#ffe5bd','#000000')
                                  }}>Т</div>
                             </div>
                             </div>
                    </>
                    :
                <div className="pagination-controlsR" style={{position:"relative"}}>
                        <div style={{position:"absolute",  width:"100%", top:"-27px"}}>
                        <Progress bookText={bookText} numberPage={currentPage} backColor={backColor} textColor={textColor}/>
                        </div>
                        <img src={backColor==='#1E1E1E'? leftWhite: pageLeft} alt="<" onClick={()=>currentPage !== 0 ? handlePrevPage():null} className="pageLR" style={currentPage === 0 ? { opacity:"0.2", marginLeft:"3%" } : {marginLeft:"3%" }}/>
                        <img src={backColor==='#1E1E1E' ? selboWhite: selbo} alt="S" onClick={goHome} className="btnSelbo"/>
                        <img src={backColor==='#1E1E1E'? settingWhite: setting} alt="o" className="btnSelbo" onClick={()=> setVisModal(!visModal)}/>
                        <img src={backColor==='#1E1E1E'?rightWhite: pageRight} alt=">" onClick={()=>currentPage !== bookPages.length - 1 ? handleNextPage():null} className="pageLR" style={currentPage === bookPages.length - 1 ? { opacity:"0.2", marginRight:"3%" } : {marginRight:"3%"}}/>
                </div>
                }
                </div>
            ) : (
                <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                <LoadingScreen/>
                <p style={{magin:"0px", marginTop:"10px", color:textColor, fontSize:"18px"}}>Загрузка книги...</p>
                </div>
                // <div className="cont-loading">
                // <h1>S E L B O</h1>
                // <p style={{magin:"0px", marginTop:"10px", color:textColor}}>Загрузка книги...</p>
                // </div>
            )}
        </div>
    );
}

export { Reader };
