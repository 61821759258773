import '../css/Profile.css'
import dots from '../image/dots.png'
// import imgTest from '../image/testImg.JPG'
import getSaveAds from './getSaveAds';
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

const ListSaveAds = props => {
    const moment = require('moment');
    require('moment/locale/ru');

    const [listData, setListData] = useState(null)


    useEffect(() => {
        const fetchData = async () => {
          const allAds = props.allData;
          const arr = await getSaveAds("arrArts1")
          console.log("Вытащенные данные из локал стораге!!!", arr);
          if (arr && allAds) {
            console.log("Вытащенные данные из локал стораге", arr);
            const filteredAds = allAds.filter(ad => arr.includes(ad.formItems.art));
            setListData(filteredAds)
            console.log("Отфильтрованные по сохраненным объявления", filteredAds);
          }
        };
    
        fetchData();
      }, [props]);

    return (
        <div>
            <div className="allMyAds">
                {listData ? listData.map((ad, id) => (
                 <Link to={`/ad${ad.formItems.art}`} style={{textDecoration:"none", color:"black", width:"100%"}}>
                    <div className="eachmyAd" key={id}>
                    <div style={{position:"relative", width:"18%", paddingTop:"18%", borderRadius:"10px", marginRight:"3%"}}>
                       <img style={{position:"absolute", top:"0", left:"0", width:"100%", height:"100%"}}  src={ad.formItems.imageUrl[1]} alt="?" className="imgMyAds"/>
                    </div>
                    <div className="contTextMyAds">
                        <p className="text1MyAd">{ad.formItems.nameBook}</p>
                        <p className="text2MyAd" >{ad.formItems.author}</p>
                        <p className="text3MyAd">Сохранено {moment(ad.formItems.dateAd).format('LL')}</p>
                    </div>
                    <img src={dots} className="imgMyAdsRight" alt="..."/>
                    </div>
                </Link>
                )
            )
                    :
                    <p >Нет сохраненных книг</p>

            }

            </div>
        </div>
    )
}

export default ListSaveAds