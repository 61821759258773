export const testParser = (fb2Content) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(fb2Content, 'application/xml');
  
    // Проверка на ошибки при парсинге XML
    const parserError = xmlDoc.getElementsByTagName('parsererror');
    if (parserError.length > 0) {
      console.error('Ошибка при парсинге FB2 файла.');
      return null;
    }
  
    // Массив для хранения страниц
    const pages = [];
  
    // Ищем все <title> элементы в документе
    const titles = xmlDoc.getElementsByTagName('title');
  
    // Проходим по каждому <title> элементу
    Array.from(titles).forEach(title => {
      let pageContent = '';
  
      // Добавляем содержимое <title> как заголовок <h3>
      const titleParagraphs = title.getElementsByTagName('p');
      Array.from(titleParagraphs).forEach(paragraph => {
        pageContent += `<h3>${paragraph.textContent}</h3>`;
      });
  
      // Сохраняем все последующие теги <p> или <v>, игнорируя <poem> и <stanza>
      let nextElement = title.nextElementSibling;
      while (nextElement) {
        // Пропускаем <poem> и <stanza> теги, включая их содержимое
        if (nextElement.tagName === 'poem' || nextElement.tagName === 'stanza') {
                  // Проходим по всем тегам <v> внутри <poem> или <stanza>
        const vElements = nextElement.getElementsByTagName('v');
        Array.from(vElements).forEach(vElement => {
          pageContent += `<p><i>${vElement.textContent}</i></p>`;
        });
        nextElement = nextElement.nextElementSibling;
        continue;
        }
        else if (nextElement.tagName === 'cite' ) {
          // Проходим по всем тегам <p> внутри <cite>
        const vElements = nextElement.getElementsByTagName('p');
        Array.from(vElements).forEach(vElement => {
          pageContent += `<p><i>${vElement.textContent}</i></p>`;
        });
        nextElement = nextElement.nextElementSibling;
        continue;
        }
  
        // Добавляем только <p> или <v> теги
        if (nextElement.tagName === 'p' || nextElement.tagName === 'v') {
          pageContent += `<p>${nextElement.textContent}</p>`;
        }
  
        nextElement = nextElement.nextElementSibling;
      }
  
      // Добавляем сформированный контент как новую страницу
      pages.push(pageContent);
    });
  
    return pages;
  };
  