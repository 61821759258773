export const extractInformation = (fb2Content) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(fb2Content, 'application/xml');

  // Проверка на ошибки при парсинге XML
  const parserError = xmlDoc.getElementsByTagName('parsererror');
  if (parserError.length > 0) {
    console.error('Ошибка при парсинге FB2 файла.');
    return null;
  }

  // Извлекаем название книги
  const titleElement = xmlDoc.querySelector('title-info > book-title');
  const bookTitle = titleElement ? titleElement.textContent : 'Без названия';

  // Извлекаем автора книги
  const authorElement = xmlDoc.querySelector('title-info > author');
  const firstName = authorElement ? authorElement.querySelector('first-name')?.textContent : '';
  const lastName = authorElement ? authorElement.querySelector('last-name')?.textContent : '';
  const author = `${firstName} ${lastName}`.trim() || 'Автор неизвестен';

  // Извлекаем описание книги
  const descriptionElement = xmlDoc.querySelector('title-info > annotation');
  const description = descriptionElement ? descriptionElement.textContent.trim() : 'Описание отсутствует';

  // Извлекаем жанр книги
  const genreElement = xmlDoc.querySelector('title-info > genre');
  const genre = genreElement ? genreElement.textContent : 'Жанр не указан';


  return {
    title: bookTitle,
    author,
    description,
    genre
  };
};
