const saveEachBook = (key, art, link, page, part) => {
    console.log("Артикль:", art);
    
    if (window.Telegram) {
      const tg = window.Telegram.WebApp;
      tg.ready();
      
      // Объект с данными для сохранения
      const dataToSave = {
        art: art, 
        link: link,
        page: page,
        part: part
      };
      
      // Функция для сохранения данных в CloudStorage
      const saveData = () => {
        tg.CloudStorage.setItem(key, JSON.stringify(dataToSave), (error, success) => {
          if (!error) {
            console.log('Данные успешно сохранены');
          } else {
            console.error('Ошибка при сохранении данных в CloudStorage', error);
          }
        });
      };
  
      // Перезаписываем данные для указанного ключа
      saveData();
    }
  };
  
  export default saveEachBook;
  