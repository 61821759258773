export const extractCoverImage = (fb2Content) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(fb2Content, 'application/xml');
  
    // Проверка на ошибки при парсинге XML
    const parserError = xmlDoc.getElementsByTagName('parsererror');
    if (parserError.length > 0) {
      console.error('Ошибка при парсинге FB2 файла.');
      return null;
    }
  
    // Извлекаем тег <binary>, содержащий изображение
    const binaryElements = xmlDoc.getElementsByTagName('binary');
    let base64Cover = null;
    for (let i = 0; i < binaryElements.length; i++) {
      const binaryElement = binaryElements[i];
      const contentType = binaryElement.getAttribute('content-type');
      if (contentType && contentType.startsWith('image')) {
        base64Cover = binaryElement.textContent;
        break;
      }
    }
  
    // Если нашли картинку, возвращаем её в формате Base64
    if (base64Cover) {
      return `data:image/jpeg;base64,${base64Cover.trim()}`;
    } else {
      console.warn('Обложка не найдена в файле FB2.');
      return null;
    }
  };
  